import { mainBackend, lmsBackend } from "../../environment/environment";

const width = window.screen.width * window.devicePixelRatio;
const height = window.screen.height * window.devicePixelRatio;

const dimensions = {
  width,
  height,
  dimensions: height + "x" + width
};

class identityVerficationService {

  uploadCnicFrontBack(obj) {
    return lmsBackend
      .post("users/upload-cinc-front-back", obj)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  identityVerficationSelfie(obj) {
    return lmsBackend
      .post("users/upload-selfie", obj)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  uploadImageAndGetUrlS3(formData) {
    return lmsBackend
      .post("uploader/uploadImageToS3", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        file: function(progressEvent) {
          this.isImgUploaded = false;
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        toastr.error("Oops! It seems service is down.");
        return err;
      });
  }

}

export default new identityVerficationService();
