<template src="./contact-us.html"></template>

<script>
import appHeader from "../shared-module/components/app-header/app-header.vue";
import { lmsBackend } from "../../environment/environment";
import router from "../router/app-routes";

export default {
  name: "practices",
  data() {
    return {
      form: {
        from: 'core-assessment',
        email: null,
        name: null,
        message: null,
      },
      loading: false,
    };
  },
  components: {
    "app-header": appHeader,
  },
  async mounted() {
  },
  destroyed() {
  },
  beforeDestroy() {
  },
  methods: {
    async submit() {
      this.loading = true;
      try{
        const res = await lmsBackend.post("users/contact-us", this.form);
        router.push('/landing');
      }
      catch (e){
        this.$toaster.error('something went wrong. try again!');
      }
     console.log(res);
      this.loading = false;
    }
  },
};
</script>

<style src="./contact-us.css" scoped></style>
