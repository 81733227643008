<template src="./instructor-slider.html"></template>

<script>
export default {
  name: "instructor-slider",
  props: {
  },
  components: {
  },
  directives: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  beforeDestroy() {
  },
 
};
</script>

<style src="./instructor-slider.css" scoped></style>
