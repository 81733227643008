<template src="./complete-profile.html"></template>

<script>
import appHeader from "../shared-module/components/app-header/app-header.vue";
import validatorService from "./../app-services/validator-service";
import CompleteProfileService from "./../app-services/app-complete-profile-service";
import LoginService from "./../app-services/app-login-service";
import toastr from "toastr";
import customStorageService from "../app-services/custom-storage-service";

export default {
  name: "practices",
  data() {
    return {
      step: 2,
      otpCode: false,
      getOTP: false,
      isPageLoading: true,
      otpFields: ["", "", "", ""],
      loader: false,
      errorMessage: "",
      send_otp: {
        email: "youremail@gmail.com",
        type: "email",
        user_id: "",
      },
      validate_otp: {
        code: "",
        user_id: "",
      },
      academicQualificationsList: [],
      professionalQualificationsList: [],
      levelsList: [],
      subjectsList: [],
      BoardsList: [],
      loading: false,
      completeProfile: {
        guardian_name: "",
        cnic: "",
        city: "",
        home_address: "", // current address
        permanent_address: "",
        mailing_address: "", 
        academic_qualification: "",
        professional_qualification: "", // drop down
        board_id: "", // drop down
        level_id: "", // drop down
        subject_id: "", // drop down
      },
      cnicPattern: /^\d{5}-\d{7}-\d$/,
      timeCount: 30,
      resendCode: false,
      errorMessageGuardian: '',
      whichFildError:'',
      filedError: {
        guardianName: '',
        cnic: '',
        city: '',
        currentAddress: '',
        permanentAddress: '',
        mailingAddress: '',
        academicQualification: "",
        professionalQualification: "", // drop down
        boardId: "", // drop down
        levelId: "", // drop down
        subjectId: "", // drop down
      },
    };
  },
  components: {
    "app-header": appHeader,
  },
  async mounted() {
    this.$refs.otpInput0.addEventListener("paste", this.onPaste);
    this.$refs.otpInput1.addEventListener("paste", this.onPaste);
    this.$refs.otpInput2.addEventListener("paste", this.onPaste);
    this.$refs.otpInput3.addEventListener("paste", this.onPaste);
    this.getAcademicQualificationsData();
    await this.getProfessionalQualificationsData();
    this.getBoardsData();
    this.getLevelsData();
    this.getSubjectsData();
    await this.userStepTracking();
  },
  created() {
    setTimeout(() => {
      this.isPageLoading = false;
    }, 2000);
  },
  destroyed() {},
  beforeDestroy() {
    // Remove event listeners to prevent memory leaks
    this.$refs.otpInput0.removeEventListener("paste", this.onPaste);
    this.$refs.otpInput1.removeEventListener("paste", this.onPaste);
    this.$refs.otpInput2.removeEventListener("paste", this.onPaste);
    this.$refs.otpInput3.removeEventListener("paste", this.onPaste);
  },
  methods: {
    prev() {
      console.log("------steps-----------", this.step);
      
      this.step--;
    },
    // validateCNIC(cnic) {
    // console.log("-------",cnic);
    // const regex =/^[0-9]{13}$/;
    // return regex.test(cnic);
    // },
    async resend() {
      if (this.resendCode) {
        let sendOTPResponse = await LoginService.sendOTP(this.send_otp);
        if (sendOTPResponse.success) {
          toastr.success(
            "Verification Code sent again please check your email"
          );
        } else {
          toastr.error(sendOTPResponse.msg, "Error");
          throw sendOTPResponse.msg;
        }
        this.resendCode = false;
        this.timeCount = 30;
        this.intTimer();
      }
    },
    intTimer() {
      let timer = setInterval(() => {
        if (this.timeCount > 0) {
          this.timeCount--;
        } else {
          clearInterval(timer);
          this.resendCode = true;
        }
      }, 1000);
    },
    next() {
      // if(this.step===2)
      // {
      //   if(!this.validateCNIC(this.completeProfile.cnic))
      //   {
      //     throw "please enter valid cnic";
      //   }
      // }
      this.step++;
      if (this.step == 6) {
        (this.otpCode = true), (this.step = 6);
      }
      if (this.step == 7) {
        this.intTimer();
      }
      console.log("------steps-----------", this.step);
    },
    onInput(index, event) {
      const key = event.data;
      if (key === null || key === undefined) {
        // Backspace key pressed
        if (index > 0) {
          this.$refs[`otpInput${index - 1}`].focus();
        }
      } else if (
        this.otpFields[index].length === 1 &&
        index < this.otpFields.length - 1
      ) {
        this.$refs[`otpInput${index + 1}`].focus();
      }

      // if (this.otpFields.every(value => value !== '')) {
      //   this.validate_otp.code = this.otpFields.join('');
      // } else {
      //   toastr.error("Fill all boxes");
      // }
    },
    // Function to handle pasting into the input fields
    onPaste(event) {
      event.preventDefault(); // Prevent default paste behavior
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text");

      // Extract only numbers from the pasted data
      const numbersOnly = pastedData.replace(/\D/g, "").split("");

      // Update the otpFields array with pasted data
      for (
        let i = 0;
        i < numbersOnly.length && i < this.otpFields.length;
        i++
      ) {
        this.$set(this.otpFields, i, numbersOnly[i]);
        if (i < this.otpFields.length - 1) {
          this.$refs[`otpInput${i + 1}`].focus(); // Move focus to the next input field
        }
      }

      // if (this.otpFields.every(value => value !== '')) {
      //   this.validate_otp.code = this.otpFields.join('');
      // } else {
      //   toastr.error("Fill all boxes");
      // }
    },

    async postCompleteProfile(data) {
      console.log(
        this.completeProfile,
        "completeProfile -------------------------"
      );

      if (!this.loader) {
        this.loader = true;
        this.errorMessage = "";

        try {
          const fields = [
            "guardian_name",
            "cnic",
            "city",
            "home_address",
            "permanent_address",
            "mailing_address",
            "academic_qualification",
            "professional_qualification",
            "board_id",
            "level_id",
            "subject_id",
          ];
          let isDataValidated = await validatorService.validate(
            fields,
            this.completeProfile
          );
          if (isDataValidated) {
            throw new Error(isDataValidated);
          } else {
            let completeProfileResponse = await CompleteProfileService.completeProfile(
              data
            );
            if (completeProfileResponse.success) {
              // customStorageService.setUser(completeProfileResponse.user);
              toastr.success("User Update");
            } else {
              throw completeProfileResponse.msg;
            }
          }
          this.loader = false;
        } catch (error) {
          this.errorMessage = error;
          this.loader = false;
        }
      }
    },

    async getLevelsData() {
      let getLevelResponse = await CompleteProfileService.getLevels();
      if (getLevelResponse.success) {
        this.levelsList = getLevelResponse.data;
      } else {
        throw getLevelResponse.message;
      }
    },

    async getBoardsData() {
      let getBoardResponse = await CompleteProfileService.getBoards();
      if (getBoardResponse.success) {
        this.BoardsList = getBoardResponse.data;
      } else {
        throw getBoardResponse.message;
      }
    },

    async getSubjectsData() {
      let getSubjectsResponse = await CompleteProfileService.getSubjects();
      if (getSubjectsResponse.success) {
        this.subjectsList = getSubjectsResponse.data;
      } else {
        throw getSubjectsResponse.message;
      }
    },

    validateCNIC() {
      return this.cnicPattern.test(this.completeProfile.cnic);
    },

    async getAcademicQualificationsData() {
      let getAcademicQualificationsResponse = await CompleteProfileService.getAcademicQualifications();
      if (getAcademicQualificationsResponse.success) {
        this.academicQualificationsList =
          getAcademicQualificationsResponse.data;
      } else {
        throw getAcademicQualificationsResponse.message;
      }
    },

    async getProfessionalQualificationsData() {
      let getProfessionalQualificationsResponse = await CompleteProfileService.getProfessionalQualifications();
      if (getProfessionalQualificationsResponse.success) {
        this.professionalQualificationsList =
          getProfessionalQualificationsResponse.data;
      } else {
        throw getProfessionalQualificationsResponse.message;
      }
    },

formatCNIC(event) {
  let caretPosition = event.target.selectionStart;
  let cnicDigits = this.completeProfile.cnic.replace(/\D/g, "");
  let newCaretPosition = caretPosition;
  
  let formattedCNIC = "";
  
  if (cnicDigits.length <= 5) {
    formattedCNIC = cnicDigits;
  } else if (cnicDigits.length <= 12) {
    formattedCNIC = cnicDigits.slice(0, 5) + "-" + cnicDigits.slice(5);
  } else {
    formattedCNIC =
      cnicDigits.slice(0, 5) +
      "-" +
      cnicDigits.slice(5, 12) +
      "-" +
      cnicDigits.slice(12);
  }

  if (caretPosition == 6 || caretPosition == 13) {
    newCaretPosition++;
  }

  this.completeProfile.cnic = formattedCNIC;
  event.target.value = formattedCNIC;
  event.target.setSelectionRange(newCaretPosition, newCaretPosition);
},


    clearError() {
      this.errorMessage = '';
      this.whichFildError = '';
      this.filedError.guardianName = '';
      this.filedError.cnic = '';
      this.filedError.city = '';
      this.filedError.currentAddress = '';
      this.filedError.permanentAddress = '';
      this.filedError.mailingAddress = '';
      this.filedError.academicQualification = '';
      this.filedError.professionalQualification = '';
      this.filedError.boardId = '';
      this.filedError.subjectId = '';
      this.filedError.levelId = '';
    },

    async handleButtonClickCases(data) {
      this.loading = true;
      switch (this.step) {
        case 2:
          console.log(this.completeProfile, "step 2 -------------------------");
          this.errorMessage = "";
          this.clearError();
          try {
            let fields = ["guardian_name", "cnic"];
            let isDataValidated = await validatorService.validate(
              fields,
              this.completeProfile
            );
            if (isDataValidated) {
              this.loading = false;
              throw new Error(isDataValidated);
            } else {
              if (!this.validateCNIC()) {
              this.loading = false;
                this.errorMessage =
                  "Please enter CNIC ***** - ******* - *";
                return;
              }

              let stepData = new Object();
              stepData.guardian_name = this.completeProfile.guardian_name;
              stepData.cnic = this.completeProfile.cnic;

              let completeProfileResponse = await CompleteProfileService.completeProfile(
                stepData
              );
              if (completeProfileResponse.success) {
                // customStorageService.setUser(completeProfileResponse.user);
                toastr.success("Successfully Submitted");
                this.next();
                this.loading = false;
              } else {
                toastr.error(completeProfileResponse.msg, "Error");
                this.loading = false;
                throw completeProfileResponse.msg;
              }
            }
          } catch (error) {
            this.whichFildError = error;
            this.loader = false;
            this.loading = false;

            if(this.whichFildError == "Parent/ Husband name is required"){
              this.filedError.guardianName = this.whichFildError;
            }
            if(this.whichFildError == "CNIC is required"){
              this.filedError.cnic = this.whichFildError;
            }
          }
          break;
        case 3:
          console.log(this.completeProfile, "step 3 -------------------------");
          this.errorMessage = "";
          this.clearError();
          try {
            let fields = [
              "city",
              "home_address",
              "permanent_address",
              "mailing_address",
            ];
            let isDataValidated = await validatorService.validate(
              fields,
              this.completeProfile
            );
            if (isDataValidated) {
              this.loading = false;
              throw new Error(isDataValidated);
            } else {
              let stepData = new Object();
              stepData.city = this.completeProfile.city;
              stepData.home_address = this.completeProfile.home_address;
              stepData.permanent_address = this.completeProfile.permanent_address;
              stepData.mailing_address = this.completeProfile.mailing_address;

              let completeProfileResponse = await CompleteProfileService.completeProfile(
                stepData
              );
              if (completeProfileResponse.success) {
                // customStorageService.setUser(completeProfileResponse.user);
                toastr.success("Successfully Submitted");
                this.getAcademicQualificationsData();
                this.getProfessionalQualificationsData();
                this.next();
                this.loading = false;
              } else {
                this.loading = false;
                toastr.error(completeProfileResponse.msg, "Error");
                throw completeProfileResponse.msg;
              }
            }
          } catch (error) {
            this.whichFildError = error;
            this.loader = false;
            this.loading = false;
            if(this.whichFildError == "City is required"){
              this.filedError.city = this.whichFildError;
            }
            if(this.whichFildError == "Current address is required"){
              this.filedError.currentAddress = this.whichFildError;
            }
            if(this.whichFildError == "Permanent address is required"){
              this.filedError.permanentAddress = this.whichFildError;
            }
            if(this.whichFildError == "Mailing address is required"){
              this.filedError.mailingAddress = this.whichFildError;
            }
          }
          break;
        case 4:
          console.log(this.completeProfile, "step 4 -------------------------");
          this.errorMessage = "";
          this.clearError();
          try {
            let fields = [
              "academic_qualification",
              // "professional_qualification",
            ];
            let isDataValidated = await validatorService.validate(
              fields,
              this.completeProfile
            );
            if (isDataValidated) {
              this.loading = false;
              throw new Error(isDataValidated);
            } else {
              let stepData = new Object();
              stepData.academic_qualification = this.completeProfile.academic_qualification;
              stepData.professional_qualification = this.completeProfile.professional_qualification;

              let completeProfileResponse = await CompleteProfileService.completeProfile(
                stepData
              );
              if (completeProfileResponse.success) {
                // customStorageService.setUser(completeProfileResponse.user);
                toastr.success("Successfully Submitted");

                this.getBoardsData();
                this.getLevelsData();
                this.getSubjectsData();
                this.next();
                this.loading = false;
              } else {
                this.loading = false;
                toastr.error(completeProfileResponse.msg, "Error");
                throw completeProfileResponse.msg;
              }
            }
          } catch (error) {
            this.whichFildError = error;
            this.loader = false;
            this.loading = false;
            if(this.whichFildError == "Academic qualification is required"){
              this.filedError.academicQualification = this.whichFildError;
            }
            // if(this.whichFildError == "Professional qualification is required"){
            //   this.filedError.professionalQualification = this.whichFildError;
            // }
          }
          break;
        case 5:
          console.log(this.completeProfile, "step 5 -------------------------");
          this.errorMessage = "";
          this.clearError();
          try {
            let fields = ["board_id", "level_id", "subject_id"];
            let isDataValidated = await validatorService.validate(
              fields,
              this.completeProfile
            );
            if (isDataValidated) {
              this.loading = false;
              throw new Error(isDataValidated);
            } else {
              let stepData = new Object();
              stepData.board_id = this.completeProfile.board_id;
              stepData.level_id = this.completeProfile.level_id;
              stepData.subject_id = this.completeProfile.subject_id;

              let completeProfileResponse = await CompleteProfileService.completeProfile(
                stepData
              );
              if (completeProfileResponse.success) {
                // customStorageService.setUser(completeProfileResponse.user);
                this.send_otp.user_id = completeProfileResponse.user.id;
                this.send_otp.email = completeProfileResponse.user.email;
                this.validate_otp.user_id = this.send_otp.user_id;
                toastr.success("User Update");
                customStorageService.setFilled(true);
                this.next();
                this.loading = false;
              } else {
                this.loading = false;
                toastr.error(completeProfileResponse.msg, "Error");
                throw completeProfileResponse.msg;
              }
            }
          } catch (error) {
            this.whichFildError = error;
            this.loader = false;
            this.loading = false;
            if(this.whichFildError == "Subject is required"){
              this.filedError.subjectId = this.whichFildError;
            }
            if(this.whichFildError == "Level is required"){
              this.filedError.levelId = this.whichFildError;
            }
            if(this.whichFildError == "Board is required"){
              this.filedError.boardId = this.whichFildError;
            }
          }
          break;
        case 6:
          console.log(this.send_otp, "step 6 -------------------------");
          this.errorMessage = "";

          let sendOTPResponse = await LoginService.sendOTP(this.send_otp);
          if (sendOTPResponse.success) {
            toastr.success("Verification Code sent please check your email");
            this.next();
            this.loading = false;
          } else {
            this.loading = false;
            toastr.error(sendOTPResponse.msg, "Error");
            throw sendOTPResponse.msg;
          }
          break;
        case 7:
          console.log(this.validate_otp, "step 7 -------------------------");
          this.errorMessage = "";
          try {
            if (this.otpFields.every((value) => value !== "")) {
              this.validate_otp.code = this.otpFields.join("");
              let validateOTPResponse = await LoginService.validateOTP(
                this.validate_otp
              );
              console.log(validateOTPResponse);
              if (validateOTPResponse.success) {
                toastr.success("Code verified");

                let stepData = new Object();
                stepData.unconfirmed_email = "false";

                let completeProfileResponse = await CompleteProfileService.completeProfile(
                  stepData
                );
                if (completeProfileResponse.success) {
                  // customStorageService.setUser(completeProfileResponse.user);
                  this.next();
                  this.loading = false;
                } else {
                  this.loading = false;
                  toastr.error(completeProfileResponse.msg, "Error");
                  throw completeProfileResponse.msg;
                }
              } else if (validateOTPResponse.data.message == "Invalid Code") {
                this.loading = false;
                toastr.error("Invalid Code");
              } else {
                this.loading = false;
                toastr.error("An unexpected error occurred");
                throw validateOTPResponse.msg;
              }
            } else {
              this.loading = false;
              toastr.error("Fill all boxes");
            }
          } catch (error) {
            this.errorMessage = error;
            this.loader = false;
            this.loading = false;
          }
          break;
        case 8:
          let res = await CompleteProfileService.getUserStep();
          if (res.user.unconfirmed_email === "false") {
            this.loading = false;
            this.$router.push("/identity-verification");
            this.step = 8;
          }
          else{
            this.step=6;
          }
          break;
        default:
          // Call function for other steps
          break;
      }
    },

    async userStepTracking() {
      try {
        let res = await CompleteProfileService.getUserStep();
        console.log("***********************");
        // console.log(res);

        if (res.user.cnic == null || res.user.cnic == "") {
          this.step = 2;
        } else {
          if (res.user.mailing_address !== null) {
            this.completeProfile.cnic = res.user.cnic;
            this.completeProfile.guardian_name = res.user.guardian_name;

            if (res.user.academic_qualification !== null) {
              this.completeProfile.city = res.user.city;
              this.completeProfile.home_address = res.user.current_address;
              this.completeProfile.permanent_address = res.user.permanent_address;
              this.completeProfile.mailing_address = res.user.mailing_address;

              if (res.user.subject_id !== null) {
                this.completeProfile.board_id = res.user.board_id;
                this.completeProfile.level_id = res.user.level_id;
                this.completeProfile.subject_id = res.user.subject_id;
                this.completeProfile.academic_qualification = res.user.qualification;
                this.completeProfile.professional_qualification  = res.user.professional_qualification;

                if (res.user.unconfirmed_email === "false") {
                  this.$router.push("/identity-verification");
                }
                else if (res.user.unconfirmed_email === "true"){
                  this.step = 6;
                }

                this.step = 6;
                this.getOTP = true;
                this.send_otp.user_id = res.user.id;
                this.send_otp.email = res.user.email;
                this.validate_otp.user_id = this.send_otp.user_id;
              } else {
                this.completeProfile.academic_qualification = res.user.qualification;
                this.completeProfile.professional_qualification  = res.user.professional_qualification;

                this.getAcademicQualificationsData();
                this.getProfessionalQualificationsData();
                this.getBoardsData();
                this.getLevelsData();
                this.getSubjectsData();
                this.step = 5;
              }
            } else {
              this.getAcademicQualificationsData();
              this.getProfessionalQualificationsData();
              this.getBoardsData();
              this.getLevelsData();
              this.getSubjectsData();
              this.step = 4;
            }
          } else {
            this.step = 3;
            this.completeProfile.city = res.user.city;
            this.completeProfile.home_address = res.user.current_address;
            this.completeProfile.permanent_address = res.user.permanent_address;
            this.completeProfile.mailing_address = res.user.mailing_address;
            this.getAcademicQualificationsData();
            this.getProfessionalQualificationsData();
            this.getBoardsData();
            this.getLevelsData();
            this.getSubjectsData();
          }
          this.completeProfile.city = res.user.city;
          this.completeProfile.home_address = res.user.current_address;
          this.completeProfile.permanent_address = res.user.permanent_address;
          this.completeProfile.mailing_address = res.user.mailing_address;
        }
      } catch (error) {
        this.errorMessage = error;
        this.loader = false;
      }
    },
  },
  watch: {},
};
</script>

<style src="./complete-profile.css" scoped></style>
