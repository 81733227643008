<script>
export default {
  name: 'close',
  mounted() {
    this.closeWindow();
  },
  methods: {
    closeWindow() {
      // Close window
      window.close();
   }
  } 
}
</script>