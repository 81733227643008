<template src="./exam.html"></template>

<script>
import axios from 'axios';
import appHeader from "../shared-module/components/app-header/app-header.vue";
import FaceRecognitionService from "./../app-services/face-recognition-service";
import CustomStorageService from "./../app-services/custom-storage-service";
import toastr from "toastr";
import quizService from '../quiz-module/services/quiz-service';
import customStorageService from './../app-services/custom-storage-service';


export default {
  name: "practices",
  data() { 
    return {
      step: 1,
      loader: false,
      showVideo: true,
      showDataContainer: false,
      timeoutId: null,
      shouldCheckCameraPermission: true,
      stepTwoPermission: false,
      errorMessage: '',
      detectFaceAI: null,
      faceNotMatch: 0,
      exam: this.$route.path,
      isMock:false,
      stepThreePermission: false,
      stepFourPermission: false,
      goToExamScreen: false,
      stream: null,
      type:null,
      termCondition:false
    };
      
  },
  components: {
    "app-header": appHeader,
  },
  mounted() {
    this.type = this.$route.params.type;
    if(this.type === 'mock'){
      this.isMock=true;
    }
    console.log("🚀 ~ data ~ exam:", this.type)
  },
  destroyed() {
  },
  beforeDestroy() {
  },
  methods: {
    prev() {
      if(this.step > 1){
        this.step--;
        console.log("------steps-----------", this.step);
      }
    },
    async next() {
      this.step++;
      console.log(this.step, "++++++++++++++++++++++++++")
      console.log(this.userId);
      if(this.step == 3){
        setTimeout(() => {
          $('#trackFace').modal('show');
        }, 1000);
      }
      if(this.step == 4){
        setTimeout(() => {
          $('#camerPermission').modal('show');
        }, 1000);
      }
      if(this.step == 5){
        if(this.goToExamScreen === true){
          // if(this.isMock)
          // {
            if (this.stream) {
              this.stream.getTracks().forEach((track) => {
              track.stop();
              this.showVideo = false;
              });
            }
            this.loader = true;
            let userLevel = CustomStorageService.getUser()?.level_id;
            let userSubject = CustomStorageService.getUser()?.subject_id;
            let userBoard = CustomStorageService.getUser()?.board_id;
            const res=await quizService.getMockQuiz(this.type, userSubject, userBoard, userLevel);
            console.log(res);
            if(res.data.message==='Attempts_Used')
              {
                toastr.error("You have used your attempts Please Purchase Course Again");
                this.$router.push('/payment');
              }
              else if(res.data._id)
            {
              this.loader = false;
              customStorageService.setUserQuiz(res.data);
              this.$router.push('/exam/'+this.type+'/'+res.data._id);
            }
          // }
        } else {
            toastr.error('Scan again');
            toastr.warning('To proceed, your face must be matched at least once.');
          } 
      }
    },

    setStepThreePermission() {
      console.log("step three true >>>>>>>>>>>>>>>")
      this.stepThreePermission = true;
      this.next();   
    },


    startCameraAndSendImage() {
      console.log("ask for permission----");
      this.stepFourPermission = true;
      let video = this.$refs.video;
      let canvas = this.$refs.canvas;
      let dataurl = this.$refs.dataurl;

      const handleSuccess = (newStream) => {
        console.log("successfully granted permission--------");
        this.stream = newStream;
        video.srcObject = this.stream;
        video.style.display = 'block';
        this.showVideo = true;
      };

      const stopMediaTracks = () => {
        console.log("media track stopped-----");
        if (this.stream) {
          this.stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      };

      const handleError = (error) => {
        if (error.name === 'NotAllowedError') {
          console.error('Access to camera denied. Please allow access to continue.');
          stopMediaTracks();
          this.askForCameraPermission();
        } else if (error.name === 'NotFoundError') {
          console.error('No camera found on this device.');
        } else {
          console.error('Error accessing camera:', error.message);
        }
      };

      this.askForCameraPermission = () => {
        console.log("ASk for camera permission");
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then((newStream) => {
              stopMediaTracks();
              handleSuccess(newStream);
            })
            .catch((err) => {
              handleError(err);
            });
        } else {
          console.error('getUserMedia is not supported on this browser.');
        }
      };

      // Initial call to ask for camera permission
      this.askForCameraPermission();
    },

    isOnline() {
      console.log("Check online status----");
      return window.navigator.onLine;
    },
    async captureAndSendImage() {
      const canvas = this.$refs.canvas;
      const dataurl = this.$refs.dataurl;
      const video = this.$refs.video;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      let image_data_url = canvas.toDataURL('image/jpeg');

      dataurl.value = image_data_url;
      
      let userId = CustomStorageService.getUser()?.id;
      console.log(userId, "#########################");
      console.log("is online-----", this.isOnline());

      if (this.isOnline()) {
        axios.post('https://isaps-ai.nearpeer.org/face', {
          image: image_data_url,
        },
          { params: { userId: userId }, },
          {
            headers: {
              'Content-Type': 'application/json',
              userId: userId
            },
          },
        )
          .then((response) => {
            console.log('Data sent successfully:', response.data);
            if (response.data && response.data.message) {
              console.log(response.data.message);
              this.detectFaceAI = response.data;
              
              if(this.detectFaceAI.message === "No face detect. Please click picture again!"){
                console.log("No face detect while camera is live")
                toastr.warning("Trouble while sacning face. Please Adjust your postion accordng to frame");
              }
              if(this.detectFaceAI.message === "More then one face detect. Please click picture again!"){
                console.log("More then one face detect")
                toastr.error("While scaning more then one face detect");
              }
              if(this.detectFaceAI.message === "Data not found in local database"){
                console.log("Issue in local database")
              }
              if(this.detectFaceAI.message === "Data not found in database"){
                console.log("User image not found in database")
              }
              if(this.detectFaceAI.message === "Face matched!"){
                this.goToExamScreen = true;
                console.log("Face matched!");
                toastr.success("Scan Face matched");
                this.next();
              }
              if(this.detectFaceAI.message === "Face not matched!"){
                console.log("Face not matched!")
                toastr.error("Face not matched please scan again");
              }
            } else {
              throw new Error('Invalid API response');
            }
          })
          .catch((error) => {
            console.error('Error sending data:', error);
          });
      } else {
        console.log('Request is not sent to the API. Internet connection is not available (the browser is offline).');
      }
    },
    
    updatePermissionStatus(permissionStatus) {
      console.log("permission status called -----function");
      const isCameraOn = permissionStatus.state === 'granted';

      if (isCameraOn) {
        console.log('Camera permission state: ON');
        this.captureAndSendImage();
      } else {
        console.log('Camera permission state: OFF (or changed)');
        if (this.shouldCheckCameraPermission) {
          this.stopDetectingFace();
          console.error('You changed the camera permission.');
          // this.shouldCheckCameraPermission = false;
          // Replace with the actual route for redirecting to the login page
          toastr.error("You change the camera permission");
          this.$router.push('/'+this.type+'/exam-pre-permission');
        }
      }
    },
    detectCameraPermissionChanges() {
      if(this.stepFourPermission == true)
      {
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        
        if (isFirefox) {
          if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ video: true })
              .then(() => {
                // Camera access is allowed
                this.updatePermissionStatus({ state: 'granted' });
              })
              .catch((error) => {
                if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                  // Camera access is denied
                  this.updatePermissionStatus({ state: 'denied' });
                } else {
                  // Other errors
                  console.error('Error accessing camera:', error);
                }
              });
          }
        }
        if (!isFirefox) {
          if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'camera' })
              .then((permissionStatus) => {
                this.updatePermissionStatus(permissionStatus);

                permissionStatus.onchange = () => {
                  this.updatePermissionStatus(permissionStatus);
                };
              })
              .catch((error) => {
                console.error('Error querying camera permission:', error);
              });
          } else {
            console.error('Permissions API and getUserMedia are not supported on this browser.');
          }
        } 
      }else {
          toastr.warning('Allow camera permission');  
          $('#camerPermission').modal('show');
          throw "Allow camera permission" 
        }

      // this.startInterval();
    },


    startInterval() {
      
      if (this.timeoutId === null) {
        
        this.timeoutId = setTimeout(() => {
          
          this.detectCameraPermissionChanges();
          clearInterval(this.timeoutId);

          this.timeoutId = null;
          this.startInterval();
        }, 30000);
      }
    },

    stopDetectingFace() {
      clearInterval(this.timeoutId);
    },


    async handleButtonClickCases(data) {
      switch (this.step) {
        case 1:
          console.log('step 1 -------------------------');
          this.errorMessage = '';
          try {
            this.next();
          } catch (error) {
            this.errorMessage = error;
            this.loader = false;
          }
          break;
        case 2:
          console.log('step 2 -------------------------');
          this.errorMessage = '';
          try {
            if(!this.stepTwoPermission)
            {
              this.termCondition = true
              toastr.error('Please accept terms to proceed');
              throw "Please accept terms to proceed";
            } else {
              this.termCondition = false
              this.stepThreePermission == false;
              this.next();
            }
            
          } catch (error) {
            this.errorMessage = error;
            this.loader = false;
          }
          break;
        case 3:
          console.log('step 3 -------------------------');
          this.errorMessage = '';
          try {
            console.log(this.stepThreePermission, "**********************");
            if(this.stepThreePermission == true)
            {
              this.next();   
            } else {
              toastr.warning('Allow face tracking permission');
              
              $('#trackFace').modal('show');
              throw "Allow face tracking permission" 
            }
          } catch (error) {
            this.errorMessage = error;
            this.loader = false;
          }
          break;
        case 4:
          console.log('step 4 -------------------------');
          if(this.goToExamScreen === true){
              this.loader = true;
              let userLevel = CustomStorageService.getUser()?.level_id;
              let userSubject = CustomStorageService.getUser()?.subject_id;
              let userBoard = CustomStorageService.getUser()?.board_id;
              const res=await quizService.getMockQuiz(this.type, userSubject, userBoard, userLevel);
              if(res.data.message==='Attempts_Used')
              {
                toastr.error("You have used your attempts Please Purchase Course Again");
                this.$router.push('/payment');
              }
              else if(res.data._id)
              {
                if (this.stream) {
                  this.stream.getTracks().forEach((track) => {
                  track.stop();
                  this.showVideo = false;
                  });
                }
                this.loader = false;
                customStorageService.setUserQuiz(res.data);
                this.$router.push('/exam/'+this.type+'/'+res.data._id);
              }
              else{
                this.loader = false;
                toastr.error("Error in generating exanm try again");
              }
            this.errorMessage = '';
            try {
              console.log(this.stepFourPermission, "**********************");
              if(this.stepThreePermission == true)
              {
                this.next();   
              } else {
                toastr.warning('Allow camera permission');  
                $('#camerPermission').modal('show');
                throw "Allow face tracking permission" 
              }
            } catch (error) {
              this.errorMessage = error;
              this.loader = false;
            }
          } else {
            toastr.error('Scan again');
            toastr.warning('To proceed, your face must be matched at least once.');
          }
          break;
        default:
          // Call function for other steps
          break;
      }
    },


  },
  watch: {},
  



};
</script>

<style src="./exam.css" scoped></style>
