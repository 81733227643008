<template src="./identity-verification.html"></template>

<script>
import appHeader from "../shared-module/components/app-header/app-header.vue";
import validatorService from "./../app-services/validator-service";
import IdentityVerificationService from "./../app-services/app-identity-verification-service";
import quizService from "../quiz-module/services/quiz-service";
import LoginService from "./../app-services/app-login-service";
import toastr from "toastr";
import FaceRecognitionService from "./../app-services/face-recognition-service";
import axios from 'axios';
import appService from "./../app-services/app-service";
import CompleteProfileService from "./../app-services/app-complete-profile-service";

export default {
  name: "practices",
  data() {
    return {
      updated_user: null,
      showVideo: true,
      showDataContainer: false,
      step: 0,
      loader: false,
      identityVerfication: {
        frontImage: null,
        backImage: null,
        selfieImage: null,
        verify: false,
      },
      exampleImage: false,
      errorMessage: '',
      fileUpload: null,
      detectFaceInCnic: false,
      detectFaceAndCnicInSelfie: false,
      stream: null,
      loading: false,
      loadingBack: false,
      loadingCapture: false
    };
  },
  components: {
    "app-header": appHeader,
  },
  async mounted() {
    this.updated_user = await CompleteProfileService.getUserStep();
    await this.userStepTracking();
    // const val = customStorageService.getfilled();
    // if (val) this.$router.push(process.env.VUE_APP_COURSE_URL);
  },
  destroyed() {
  },
  beforeDestroy() {
  },
  methods: {
    startCameraAndSendImage() {
    // let stream;
    let video = this.$refs.video;
    let canvas = this.$refs.canvas;
    let dataurl = this.$refs.dataurl;

    const handleSuccess = (newStream) => {
        this.stream = newStream;
        video.srcObject = this.stream;
        video.style.display = 'block';
        this.showVideo = true;
    };

    const stopMediaTracks = () => {
        if (this.stream) {
          this.stream.getTracks().forEach((track) => {
            track.stop();
        });
        }
    };

    const handleError = (error) => {
        if (error.name === 'NotAllowedError') {
        console.error('Access to camera denied. Please allow access to continue.');
        stopMediaTracks();
        this.askForCameraPermission();
        } else if (error.name === 'NotFoundError') {
        console.error('No camera found on this device.');
        } else {
        console.error('Error accessing camera:', error.message);
        }
    };

    this.askForCameraPermission = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then((newStream) => {
            stopMediaTracks();
            handleSuccess(newStream);
            })
            .catch(handleError);
        } else {
        console.error('getUserMedia is not supported on this browser.');
        }
    };

    // Initial call to ask for camera permission
    this.askForCameraPermission();
    },


    captureAndSendImage() {
      this.loadingCapture = true;
      this.$nextTick(() => {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const selfieInput = this.$refs.selfieInput;

      // Draw the video frame on the canvas
      const ctx = canvas.getContext('2d');
      // ctx.imageSmoothingEnabled = false; // Ensure correct color mode
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a Blob
      this.canvasToBlob(canvas)
        .then((blob) => {
          // Create a FormData object
          const formData = new FormData();
          
          // Append the blob as a file to the FormData object
          formData.append('selfieImage', blob, 'selfie_image.jpeg');

          // Clear existing files from the input
          selfieInput.value = null;

          // Simulate a file input change event with the FormData
          const changeEvent = new Event('change');
          Object.defineProperty(selfieInput, 'files', {
            value: formData.getAll('selfieImage'),
            writable: true
          });
          
          selfieInput.dispatchEvent(changeEvent);

          // Assuming you want to set the captured image as the selfieImage
          this.identityVerfication.selfieImage = URL.createObjectURL(blob);
          this.loadingCapture = false
        })
        .catch((error) => {
          console.error('Error converting canvas to blob:', error);
          this.loadingCapture = false
        });
    });
  },

  canvasToBlob(canvas) {
    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      } catch (error) {
        reject(error);
      }
    });
  },


    prev() {
      this.step--;
      this.exampleImage = false;
      // this.frontImage = null;
      // this.backImage= null;
      // this.selfieImage= null;
      console.log("------steps-----------", this.step);
    },
    next() {
      this.step++;
      console.log("------steps-----------", this.step);
    },

    async imageUpload(fileUpload){
      let identityVerficationResponse = await IdentityVerificationService.uploadImageAndGetUrlS3(fileUpload);
      if (identityVerficationResponse.success) {
        console.log(identityVerficationResponse.link)
        toastr.success("Image Uploaded");
        this.next();
      } else {
        toastr.error(identityVerficationResponse.msg, 'Error');
        throw identityVerficationResponse.msg;
      }
    },

    upload(side) {
      this.$refs[`${side}Input`].click();
    },
    removeImage(side) {
      if (side === 'front') {
        this.identityVerfication.frontImage = null;
      } else if (side === 'back') {
        this.identityVerfication.backImage = null;
      }
    },

    async previewFileCnicFront(side, event) {
      this.loading = true; 
      let file = event.target.files[0];

      // Check if the file is an image
      if (!file || !file.type.startsWith('image/')) {
        toastr.error('Please upload a valid image file.', 'Error');
        return;
      }

      let fd = new FormData();
      fd.append("file", file, file.name);
      if (file) {
        let identityVerficationResponse = await IdentityVerificationService.uploadImageAndGetUrlS3(fd);
        if (identityVerficationResponse.success) {
          this.identityVerfication.frontImage = identityVerficationResponse.link;
          toastr.success("Front CNIC Uploaded, Let us verify");
          // Convert file to base64
          // const readerbase64 = new FileReader();
          // readerbase64.onload = e => {
          //   const base64String = e.target.result.split(',')[1]; // Extract the base64 string
          //   const mimeType = file.type; // Get the MIME type of the file
          //   const dataURL64 = `data:${mimeType};base64,${base64String}`; // Construct the data URL
            
          //   // Detect Face in Image
          //   if (dataURL64 !== null) {
          //     axios.post('https://isaps-ai.nearpeer.org/find-face', {
          //       image: dataURL64,
          //     },
          //     {headers: {
          //         'Content-Type': 'application/json',
          //         id: 100001,
          //       },},
          //     )
          //       .then((response) => {
          //         console.log('Data sent successfully: To API');
          //         if (response.data && response.data.message) {
          //           if(response.data.status === false){
          //             this.identityVerfication.frontImage = null;
          //             if(response.data.message === "face not detected in image"){
          //               console.log("Actual Face not detected")
          //             }
          //             toastr.error("Face not detcted.");
          //             toastr.info("CNIC number doesn't match with the sign up data.");
          //           } else {
          //             if(response.data.message === "face detected in image"){
          //               console.log("Actual Face detected")
          //             }
          //             this.detectFaceInCnic = true;
          //             toastr.success("Face detcted in CNIC");
          //           }
          //         } else {
          //           toastr.error("Erroe occure while cnic verification");
          //           throw new Error('Invalid API response');
          //         }
          //         this.$refs[`${side}Input`].value = ''; 
          //         this.loading = false;
          //       })
          //       .catch((error) => {
          //         this.loading = false;
          //         console.error('Error sending data:', error);
          //         toastr.error("Error sending data");
          //       });
          //   } else {
          //     console.log('Request is not sent to the API. Internet connection is not available (the browser is offline).');
          //     toastr.error("Check internet connection");
          //   }

          // };
          // readerbase64.readAsDataURL(file);
          let fd = new FormData();
          fd.append("files", file, file.name);
          try{
            let res =  await quizService.getDaqResultFromImage(fd)
            if (res.data && res.data.success) {
              const ocrText = res.data.answer;
              console.log(ocrText, this.updated_user.user.cnic);
              if(ocrText.includes(this.updated_user?.user?.cnic)){
                this.detectFaceInCnic = true;
                toastr.success("CNIC Front Side Validated");
              }
              else {
                this.identityVerfication.frontImage = null;
                toastr.info("CNIC number doesn't match with the sign up data.");
                toastr.error("Invalid CNIC");
              }
            }
            this.loading = false; 
            this.$refs[`${side}Input`].value = '';
          }
          catch (e) {
            this.$refs[`${side}Input`].value = '';
            this.identityVerfication.frontImage = null;
            toastr.error("Something went wrong while validating CNIC. Please upload again!");
            this.loading = false; 
          }
          
        } else {
          toastr.error(identityVerficationResponse.msg, 'Error');
          throw identityVerficationResponse.msg;
        }
        const reader = new FileReader();
        reader.onload = e => {
          this[`${side}Image`] = e.target.result;
        };
        reader.readAsDataURL(file);
        this.exampleImage = false;
        this.errorMessage = '';
      }
    },

    async previewFileCnicBack(side, event) {
      this.loadingBack = true;
      let cnicBackFile = event.target.files[0];

      // Check if the file is an image
      if (!cnicBackFile || !cnicBackFile.type.startsWith('image/')) {
        toastr.error('Please upload a valid image file.', 'Error');
        return;
      }

      let cnicBackFd = new FormData();
      cnicBackFd.append("file", cnicBackFile, cnicBackFile.name);
      if (cnicBackFile) {
        let identityVerificationResponse = await IdentityVerificationService.uploadImageAndGetUrlS3(cnicBackFd);
        if (identityVerificationResponse.success) {
          this.identityVerfication.backImage = identityVerificationResponse.link;
          toastr.success("Back CNIC Uploaded");
          let fd = new FormData();
          fd.append("files", cnicBackFile, cnicBackFile.name);
          try{
            let res =  await quizService.getDaqResultFromImage(fd)
            if (res.data && res.data.success) {
              const ocrText = res.data.answer;
              console.log(ocrText, this.updated_user.user.cnic);
              if(ocrText.includes(this.updated_user?.user?.cnic)){
                this.detectFaceInCnic = true;
                toastr.success("CNIC Back side Validated");
              }
              else {
                this.identityVerfication.backImage = null;
                toastr.info("Kindly submit a high-quality image of the back side of your CNIC");
                toastr.error("Invalid CNIC");
              }
            }
            this.loadingBack = false; 
            this.$refs[`${side}Input`].value = '';
          }
          catch (e) {
            this.$refs[`${side}Input`].value = '';
            this.identityVerfication.backImage = null;
            toastr.error("Something went wrong while validating CNIC. Please upload again!");
            this.loadingBack = false; 
          }
          this.$refs[`${side}Input`].value = ''; 
          this.loadingBack = false;
        } else {
          this.loadingBack = false;
          toastr.error(identityVerificationResponse.msg, 'Error');
          throw identityVerificationResponse.msg;
        }
        const reader = new FileReader();
        reader.onload = e => {
          this[`${side}Image`] = e.target.result;
        };
        reader.readAsDataURL(cnicBackFile);
        this.exampleImage = false;
        this.errorMessage = '';
      }
    },


    async previewFileSelfie(side, event) {
      let selfieFile = event.target.files[0];

      // Check if the file is an image
      if (!selfieFile || !selfieFile.type.startsWith('image/')) {
        toastr.error('Please upload a valid image file.', 'Error');
        return;
      }

      let selfieFd = new FormData();
      selfieFd.append("file", selfieFile, selfieFile.name);
      if (selfieFile) {
        let identityVerificationResponse = await IdentityVerificationService.uploadImageAndGetUrlS3(selfieFd);
        if (identityVerificationResponse.success) {
          this.identityVerfication.selfieImage = identityVerificationResponse.link;
          toastr.success("Selfie Uploaded");
          // Convert file to base64
          const readerbase64 = new FileReader();
          readerbase64.onload = e => {
            const base64String = e.target.result.split(',')[1]; // Extract the base64 string
            const mimeType = selfieFile.type; // Get the MIME type of the file
            const dataURL64 = `data:${mimeType};base64,${base64String}`; // Construct the data URL
            
            // Detect Face in Image
            if (dataURL64 !== null) {
              axios.post('https://isaps-ai.nearpeer.org/find-face', {
                image: dataURL64,
              },
              {headers: {
                  'Content-Type': 'application/json',
                  id: 100002,
                },},
              )
                .then((response) => {
                  console.log('Data sent successfully: TO API');
                  if (response.data && response.data.message) {
                    if(response.data.status === false){
                      this.identityVerfication.selfie = null;
                      if(response.data.message === "face not detected in image"){
                        console.log("Actual Face not detected")
                      }
                      toastr.error("Face not detcted.");
                      toastr.info("Follow selfie example, While uploading selfie");
                    } else {
                      if(response.data.message === "face detected in image"){
                        console.log("Actual Face detected")
                      }
                      this.detectFaceAndCnicInSelfie = true;
                      toastr.success("Face detcted");
                    }
                  } else {
                    toastr.error("Erroe occure while cnic verification");
                    throw new Error('Invalid API response');
                  }
                })
                .catch((error) => {
                  console.error('Error sending data:', error);
                  toastr.error("Error sending data");
                });
            } else {
              console.log('Request is not sent to the API. Internet connection is not available (the browser is offline).');
              toastr.error("Check internet connection");
            }

          };
          readerbase64.readAsDataURL(selfieFile);
        } else {
          toastr.error(identityVerificationResponse.msg, 'Error');
          throw identityVerificationResponse.msg;
        }
        const reader = new FileReader();
        reader.onload = e => {
          this[`${side}Image`] = e.target.result;
        };
        reader.readAsDataURL(selfieFile);
        this.exampleImage = false;
        this.errorMessage = '';
      }
    },

    seeExample() {
      this.exampleImage = !this.exampleImage
    },

    
    async handleButtonClickCases(data) {
      switch (this.step) {
        case 0:

          this.identityVerfication.frontImage = null;
          this.identityVerfication.backImage = null;
          this.errorMessage = '';
          this.next();
          break;
        case 1:
          console.log(this.identityVerfication, 'step 1 -------------------------')
          this.identityVerfication.selfieImage = null;
          this.exampleImage = false;
          this.errorMessage = '';
          try {
            let fields = [
                "frontImage",
                "backImage",
                'verify'
              ];
            let isDataValidated = await validatorService.validate(
                fields,
                this.identityVerfication
                );
                if (isDataValidated) {
                  throw new Error(isDataValidated);
                } else {
                  console.log(this.identityVerfication.frontImage, this.identityVerfication.backImage);
                  if(this.identityVerfication.frontImage !== null && this.identityVerfication.backImage !== null && this.detectFaceInCnic === true){
                    this.startCameraAndSendImage();
                    this.showVideo = true;
                    this.next();
                  }
                }
          } catch (error) {
            this.errorMessage = error;
            this.loader = false;
          }
          break;
        case 2:
          console.log(this.identityVerfication, 'step 2 -------------------------')
          this.errorMessage = '';
          try {

            if(this.identityVerfication.selfieImage === null || this.detectFaceAndCnicInSelfie === false){
               toastr.error("Please Click Photo");
            }

            let fields = [
              "selfieImage",
              ];
            let isDataValidated = await validatorService.validate(
                fields,
                this.identityVerfication
                );
                if (isDataValidated) {
                  throw new Error(isDataValidated);
                } else {

                  console.log(this.identityVerfication.selfieImage);
                  if(this.identityVerfication.selfieImage !== null && this.detectFaceAndCnicInSelfie === true){
                    // Save selfie, CNIC Front and Back
                    let frontBackCNIC = new Object();
                    frontBackCNIC.cnic_front = this.identityVerfication.frontImage;
                    frontBackCNIC.cnic_back = this.identityVerfication.backImage;

                    let selfieSave = new Object();
                    selfieSave.selfie = this.identityVerfication.selfieImage;

                    

                    let uploadCnicFrontBackResponse = await IdentityVerificationService.uploadCnicFrontBack(frontBackCNIC);
                    let uploadSelfieResponse = await IdentityVerificationService.identityVerficationSelfie(selfieSave);
                    if (uploadCnicFrontBackResponse.success && uploadSelfieResponse.success) {
                      this.stream.getTracks().forEach(function(track) {
                        track.stop();
                      });
                      toastr.success("Data saved");
                      const response =await appService.checkUserPaidStatus();
                        if(response.isCurrentPaid)
                        {
                          this.$router.push(process.env.VUE_APP_COURSE_URL);
                        }
                        else{
                          this.next();
                        }
                    } else {
                      toastr.error(uploadCnicFrontBackResponse.msg, 'Error');
                      throw uploadCnicFrontBackResponse.msg;
                    }
                  }
                }
          } catch (error) {
            this.errorMessage = error;
            this.loader = false;
          }
          break;
        case 3:
          console.log(this.identityVerfication, 'step 3 -------------------------')
             this.$router.push('/payment');
          break;
        //   case 4:
        //   console.log(this.identityVerfication, 'step 3 -------------------------')
        //    const response1 = await appService.checkUserPaidStatus();
        //    if(response1.isPaidUser)
        //    {
        //     this.$router.push(process.env.VUE_APP_COURSE_URL);
        //    }
        //    else{
        //      this.$router.push('/payment');
        //    }
        //   break;
        default:
          // Call function for other steps
          break;
      }
  },

  async userStepTracking() {
      try {
        console.log("***********************");
        console.log(updated_user);

        if (this.updated_user.user.verification_image !== null) {
          const response =await appService.checkUserPaidStatus();
          if(response.isCurrentPaid)
          {
            console.log('course purchased');
            this.$router.push(process.env.VUE_APP_COURSE_URL);
          }
          else{
            console.log('course not purchased');
            this.$router.push('/payment');
          }
         } else{
            console.log('Need verification');
            this.step = 0;
          }
      
        }
      catch (error) {
        this.errorMessage = error;
        this.loader = false;
      }
  },

  },
};
</script>

<style src="./identity-verification.css" scoped></style>
